@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans"), url(./fonts/IBMPlexSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Druk Text Bold';
  src: url('./fonts/DrukText-Bold-Trial.otf') format('truetype');
}
@font-face {
  font-family: 'Druk Text Wide Bold';
  src: url('./fonts/DrukTextWide-Bold-Trial.otf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/Proxima-Nova.otf') format('truetype');
}

* {
  font-family: "Proxima Nova", sans-serif;
}

:where(.css-dev-only-do-not-override-3i5xx7).ant-typography:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
  /* font-size: 12px; */
  /* box-sizing: border-box; */
}

p, span {
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

h1.ant-typography {
  font-family: "Druk Text Bold";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

h2.ant-typography {
  font-family: "Druk Text Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

h3.ant-typography {
  font-family: "Druk Text Wide Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
}

h4.ant-typography {
  font-family: "Druk Text Wide Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
  text-transform: uppercase;
}

body {
  margin: 0;
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #333;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #333;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

.site-logo a {
  pointer-events: none;
}

.ant-pro-top-nav-header-wide {
  max-width: 100% !important;
  padding-inline: 40px;
}

.gDgKOB {
  border-radius: 8px !important;
  background: #27262a !important;
  border: none !important;
  margin: 8px 0 !important;
}
.gDgKOB:hover {
  background: #2c2c2f !important;
}
.gDgKOB.selected {
  border: 1px solid #8752f6 !important;
  background: #541fc3 !important;
}

.ant-btn {
  border-color: rgba(217, 217, 217, 0.7);
  border-radius: 12px;
}
.ant-btn.ant-btn-link {
  border-color: transparent;
}

.borderless {
  border: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
}

.nobg {
  background: transparent !important;
}

span.gray {
  color: gray;
}